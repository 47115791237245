import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b0e9f6aa = () => interopDefault(import('../_templates/pages/blog/Blog.vue' /* webpackChunkName: "" */))
const _f00f4aaa = () => interopDefault(import('../_templates/pages/checkout/Checkout.vue' /* webpackChunkName: "" */))
const _cec0556c = () => interopDefault(import('../_templates/pages/checkout/Checkout/Order.vue' /* webpackChunkName: "" */))
const _016447a9 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Account.vue' /* webpackChunkName: "" */))
const _82f5cfee = () => interopDefault(import('../_templates/pages/checkout/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _198070d6 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _e4b01378 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _7797c9c0 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _718ef084 = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _d37183e0 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _42a594ca = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _eaf95a88 = () => interopDefault(import('../pages/customer/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _cc9fcace = () => interopDefault(import('../_templates/pages/theme/Home.vue' /* webpackChunkName: "" */))
const _c02f15ce = () => interopDefault(import('../_templates/pages/faq/Faq.vue' /* webpackChunkName: "" */))
const _6c72098b = () => interopDefault(import('../_templates/pages/wishlist/Wishlist.vue' /* webpackChunkName: "" */))
const _225e08ab = () => interopDefault(import('../_templates/pages/search/Search.vue' /* webpackChunkName: "" */))
const _7363dfff = () => interopDefault(import('../_templates/pages/customer/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _fa21202a = () => interopDefault(import('../_templates/pages/blog/BlogPost.vue' /* webpackChunkName: "" */))
const _3ebd5c2e = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollection.vue' /* webpackChunkName: "" */))
const _1b64bee1 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollectionItem.vue' /* webpackChunkName: "" */))
const _2c359f6e = () => interopDefault(import('../_templates/pages/theme/Page.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktuelt.html",
    component: _b0e9f6aa,
    name: "blog___default___default"
  }, {
    path: "/checkout",
    component: _f00f4aaa,
    name: "checkout___default___default",
    children: [{
      path: "bestilling",
      component: _cec0556c,
      name: "checkout-order___default___default"
    }, {
      path: "konto",
      component: _016447a9,
      name: "checkout-account___default___default"
    }, {
      path: "takk",
      component: _82f5cfee,
      name: "checkout-thank-you___default___default"
    }]
  }, {
    path: "/customer",
    component: _198070d6,
    meta: {"titleLabel":"Account"},
    name: "customer___default___default",
    children: [{
      path: "addresses-details",
      component: _e4b01378,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___default___default"
    }, {
      path: "my-profile",
      component: _7797c9c0,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile___default___default"
    }, {
      path: "order-history",
      component: _718ef084,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default___default"
    }, {
      path: "addresses-details/create",
      component: _d37183e0,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___default___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _42a594ca,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___default___default"
    }, {
      path: "order-history/:orderId",
      component: _eaf95a88,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default___default"
    }]
  }, {
    path: "/default",
    component: _cc9fcace,
    name: "home___default"
  }, {
    path: "/faq.html",
    component: _c02f15ce,
    name: "faq___default___default"
  }, {
    path: "/onskeliste",
    component: _6c72098b,
    name: "wishlist___default___default"
  }, {
    path: "/search",
    component: _225e08ab,
    name: "search___default___default"
  }, {
    path: "/customer/reset-password",
    component: _7363dfff,
    alias: "/customer/account/createPassword",
    name: "reset-password___default___default"
  }, {
    path: "/default/aktuelt.html",
    component: _b0e9f6aa,
    name: "blog___default"
  }, {
    path: "/default/checkout",
    component: _f00f4aaa,
    name: "checkout___default",
    children: [{
      path: "bestilling",
      component: _cec0556c,
      name: "checkout-order___default"
    }, {
      path: "konto",
      component: _016447a9,
      name: "checkout-account___default"
    }, {
      path: "takk",
      component: _82f5cfee,
      name: "checkout-thank-you___default"
    }]
  }, {
    path: "/default/customer",
    component: _198070d6,
    meta: {"titleLabel":"Account"},
    name: "customer___default",
    children: [{
      path: "addresses-details",
      component: _e4b01378,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___default"
    }, {
      path: "my-profile",
      component: _7797c9c0,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile___default"
    }, {
      path: "order-history",
      component: _718ef084,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default"
    }, {
      path: "addresses-details/create",
      component: _d37183e0,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _42a594ca,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___default"
    }, {
      path: "order-history/:orderId",
      component: _eaf95a88,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }]
  }, {
    path: "/default/faq.html",
    component: _c02f15ce,
    name: "faq___default"
  }, {
    path: "/default/onskeliste",
    component: _6c72098b,
    name: "wishlist___default"
  }, {
    path: "/default/search",
    component: _225e08ab,
    name: "search___default"
  }, {
    path: "/default/customer/reset-password",
    component: _7363dfff,
    alias: "/customer/account/createPassword",
    name: "reset-password___default"
  }, {
    path: "/customer/MyAccount/OrderHistory/SingleOrder/SingleOrder",
    component: _eaf95a88,
    name: "customer-MyAccount-OrderHistory-SingleOrder-SingleOrder___default___default"
  }, {
    path: "/default/customer/MyAccount/OrderHistory/SingleOrder/SingleOrder",
    component: _eaf95a88,
    name: "customer-MyAccount-OrderHistory-SingleOrder-SingleOrder___default"
  }, {
    path: "/default/aktuelt/:slug+.html",
    component: _fa21202a,
    name: "blogPost___default"
  }, {
    path: "/default/collection/:slug([^/.]+).html",
    component: _3ebd5c2e,
    name: "attributeCollection___default"
  }, {
    path: "/default/collection/:collection_slug/:slug+.html",
    component: _1b64bee1,
    name: "attributeCollectionItem___default"
  }, {
    path: "/aktuelt/:slug+.html",
    component: _fa21202a,
    name: "blogPost___default___default"
  }, {
    path: "/default/:slug+",
    component: _2c359f6e,
    name: "page___default"
  }, {
    path: "/collection/:slug([^/.]+).html",
    component: _3ebd5c2e,
    name: "attributeCollection___default___default"
  }, {
    path: "/collection/:collection_slug/:slug+.html",
    component: _1b64bee1,
    name: "attributeCollectionItem___default___default"
  }, {
    path: "/",
    component: _cc9fcace,
    name: "home___default___default"
  }, {
    path: "/:slug+",
    component: _2c359f6e,
    name: "page___default___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
