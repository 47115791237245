import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_plugin_22e3de56 from 'nuxt_plugin_plugin_22e3de56' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_wmIcons_5a10ff95 from 'nuxt_plugin_wmIcons_5a10ff95' // Source: ./wmIcons.js (mode: 'all')
import nuxt_plugin_nuxtplugin3c53fa4c_1815bfed from 'nuxt_plugin_nuxtplugin3c53fa4c_1815bfed' // Source: ./nuxt.plugin.3c53fa4c.js (mode: 'server')
import nuxt_plugin_image_50d7d13e from 'nuxt_plugin_image_50d7d13e' // Source: ./image.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_03178deb from 'nuxt_plugin_cookieuniversalnuxt_03178deb' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginutils_69664858 from 'nuxt_plugin_pluginutils_69664858' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginseo_684c71ac from 'nuxt_plugin_pluginseo_684c71ac' // Source: ./nuxt-i18n/plugin.seo.js (mode: 'all')
import nuxt_plugin_pluginrouting_5ef61fa3 from 'nuxt_plugin_pluginrouting_5ef61fa3' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_5cd0a6ac from 'nuxt_plugin_pluginmain_5cd0a6ac' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_runtimeplugin932a1198_147c4d53 from 'nuxt_plugin_runtimeplugin932a1198_147c4d53' // Source: ./runtime.plugin.932a1198.mjs (mode: 'all')
import nuxt_plugin_customerplugin_63511054 from 'nuxt_plugin_customerplugin_63511054' // Source: ./customer.plugin.js (mode: 'all')
import nuxt_plugin_runtimeplugin9a0b787a_61b0b730 from 'nuxt_plugin_runtimeplugin9a0b787a_61b0b730' // Source: ./runtime.plugin.9a0b787a.mjs (mode: 'all')
import nuxt_plugin_cookiebotplugin_6a774ed7 from 'nuxt_plugin_cookiebotplugin_6a774ed7' // Source: ./cookiebot.plugin.js (mode: 'server')
import nuxt_plugin_runtimeplugin92824650_399e707b from 'nuxt_plugin_runtimeplugin92824650_399e707b' // Source: ./runtime.plugin.92824650.mjs (mode: 'all')
import nuxt_plugin_runtimeplugin4a129242_25558e20 from 'nuxt_plugin_runtimeplugin4a129242_25558e20' // Source: ./runtime.plugin.4a129242.mjs (mode: 'all')
import nuxt_plugin_runtimeplugin09951f5a_6df156d1 from 'nuxt_plugin_runtimeplugin09951f5a_6df156d1' // Source: ./runtime.plugin.09951f5a.mjs (mode: 'all')
import nuxt_plugin_distplugin02061cf9_d9531fec from 'nuxt_plugin_distplugin02061cf9_d9531fec' // Source: ./dist.plugin.02061cf9.mjs (mode: 'all')
import nuxt_plugin_pluginsfc29618e14_1fec2b5b from 'nuxt_plugin_pluginsfc29618e14_1fec2b5b' // Source: ./plugins.fc.29618e14.mjs (mode: 'all')
import nuxt_plugin_coreplugin424eb2d4_4a0f33f0 from 'nuxt_plugin_coreplugin424eb2d4_4a0f33f0' // Source: ./core.plugin.424eb2d4.ts (mode: 'all')
import nuxt_plugin_pluginstokenexpired36ef7dcf_e1fe7be8 from 'nuxt_plugin_pluginstokenexpired36ef7dcf_e1fe7be8' // Source: ./plugins.token-expired.36ef7dcf.ts (mode: 'all')
import nuxt_plugin_pluginsdompurify194d0be2_b615b02e from 'nuxt_plugin_pluginsdompurify194d0be2_b615b02e' // Source: ./plugins.dompurify.194d0be2.ts (mode: 'all')
import nuxt_plugin_pluginsi18n3a5a9148_a1753392 from 'nuxt_plugin_pluginsi18n3a5a9148_a1753392' // Source: ./plugins.i18n.3a5a9148.ts (mode: 'all')
import nuxt_plugin_pluginsfixLocaleRoutes82575f32_5a470dc8 from 'nuxt_plugin_pluginsfixLocaleRoutes82575f32_5a470dc8' // Source: ./plugins.fixLocaleRoutes.82575f32.ts (mode: 'all')
import nuxt_plugin_pluginse2etestinga3bf8814_8cc9a44a from 'nuxt_plugin_pluginse2etestinga3bf8814_8cc9a44a' // Source: ./plugins.e2e-testing.a3bf8814.js (mode: 'all')
import nuxt_plugin_deviceplugin_243d78ce from 'nuxt_plugin_deviceplugin_243d78ce' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_workbox_928576c2 from 'nuxt_plugin_workbox_928576c2' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_a1d9069c from 'nuxt_plugin_metaplugin_a1d9069c' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_9889bbb4 from 'nuxt_plugin_iconplugin_9889bbb4' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_storeConfigPlugin_cbd09bc6 from 'nuxt_plugin_storeConfigPlugin_cbd09bc6' // Source: ../node_modules/@wemade-vsf/core/lib/runtime/plugins/storeConfigPlugin.mjs (mode: 'server')
import nuxt_plugin_meta_40fe1d27 from 'nuxt_plugin_meta_40fe1d27' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const store = null
  const router = await createRouter(ssrContext, config, { store })

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Nesseplast","titleTemplate":"%s - Nesseplast","meta":[{"charset":"utf8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"hid":"og:site_name","property":"og:site_name","name":"og:site_name","content":"Nesseplast"},{"hid":"og:title","name":"og:title","property":"og:title","content":"","template":(chunk) => chunk ? `${chunk} - Nesseplast` : 'Nesseplast'},{"hid":"og:description","property":"og:description","content":""},{"hid":"apple-mobile-web-app-title","property":"apple-mobile-web-app-title","content":"Nesseplast"},{"name":"og:image","hid":"og:image","property":"og:image","content":"\u002Fogimage.jpg"},{"name":"generator","content":"Vue Storefront 2"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"bodyAttrs":{"id":"html-body"},"style":[],"script":[]},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_22e3de56 === 'function') {
    await nuxt_plugin_plugin_22e3de56(app.context, inject)
  }

  if (typeof nuxt_plugin_wmIcons_5a10ff95 === 'function') {
    await nuxt_plugin_wmIcons_5a10ff95(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_nuxtplugin3c53fa4c_1815bfed === 'function') {
    await nuxt_plugin_nuxtplugin3c53fa4c_1815bfed(app.context, inject)
  }

  if (typeof nuxt_plugin_image_50d7d13e === 'function') {
    await nuxt_plugin_image_50d7d13e(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_03178deb === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_03178deb(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_69664858 === 'function') {
    await nuxt_plugin_pluginutils_69664858(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginseo_684c71ac === 'function') {
    await nuxt_plugin_pluginseo_684c71ac(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_5ef61fa3 === 'function') {
    await nuxt_plugin_pluginrouting_5ef61fa3(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_5cd0a6ac === 'function') {
    await nuxt_plugin_pluginmain_5cd0a6ac(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin932a1198_147c4d53 === 'function') {
    await nuxt_plugin_runtimeplugin932a1198_147c4d53(app.context, inject)
  }

  if (typeof nuxt_plugin_customerplugin_63511054 === 'function') {
    await nuxt_plugin_customerplugin_63511054(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin9a0b787a_61b0b730 === 'function') {
    await nuxt_plugin_runtimeplugin9a0b787a_61b0b730(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_cookiebotplugin_6a774ed7 === 'function') {
    await nuxt_plugin_cookiebotplugin_6a774ed7(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin92824650_399e707b === 'function') {
    await nuxt_plugin_runtimeplugin92824650_399e707b(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin4a129242_25558e20 === 'function') {
    await nuxt_plugin_runtimeplugin4a129242_25558e20(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin09951f5a_6df156d1 === 'function') {
    await nuxt_plugin_runtimeplugin09951f5a_6df156d1(app.context, inject)
  }

  if (typeof nuxt_plugin_distplugin02061cf9_d9531fec === 'function') {
    await nuxt_plugin_distplugin02061cf9_d9531fec(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsfc29618e14_1fec2b5b === 'function') {
    await nuxt_plugin_pluginsfc29618e14_1fec2b5b(app.context, inject)
  }

  if (typeof nuxt_plugin_coreplugin424eb2d4_4a0f33f0 === 'function') {
    await nuxt_plugin_coreplugin424eb2d4_4a0f33f0(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginstokenexpired36ef7dcf_e1fe7be8 === 'function') {
    await nuxt_plugin_pluginstokenexpired36ef7dcf_e1fe7be8(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsdompurify194d0be2_b615b02e === 'function') {
    await nuxt_plugin_pluginsdompurify194d0be2_b615b02e(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsi18n3a5a9148_a1753392 === 'function') {
    await nuxt_plugin_pluginsi18n3a5a9148_a1753392(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsfixLocaleRoutes82575f32_5a470dc8 === 'function') {
    await nuxt_plugin_pluginsfixLocaleRoutes82575f32_5a470dc8(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginse2etestinga3bf8814_8cc9a44a === 'function') {
    await nuxt_plugin_pluginse2etestinga3bf8814_8cc9a44a(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_243d78ce === 'function') {
    await nuxt_plugin_deviceplugin_243d78ce(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_928576c2 === 'function') {
    await nuxt_plugin_workbox_928576c2(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_a1d9069c === 'function') {
    await nuxt_plugin_metaplugin_a1d9069c(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_9889bbb4 === 'function') {
    await nuxt_plugin_iconplugin_9889bbb4(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_storeConfigPlugin_cbd09bc6 === 'function') {
    await nuxt_plugin_storeConfigPlugin_cbd09bc6(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_40fe1d27 === 'function') {
    await nuxt_plugin_meta_40fe1d27(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
